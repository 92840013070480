import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ReponseAPI } from "../_models/ReponseAPI";
import { GlobalService } from "./GlobalService";

@Injectable({
    providedIn: 'root'
})
export class AnimalService {
    public constructor(private http: HttpClient, private router: Router, private _GlobalService: GlobalService) { }
    public async getAllCausesDeces(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Animal/CausesDecesAnimal")
    }
}