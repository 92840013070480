<app-header-client *ngIf="!isMobile"></app-header-client>
<app-header-client-mobile *ngIf="isMobile"></app-header-client-mobile>
<app-loader *ngIf="loading"></app-loader>

<div id="ContainerMonCompteAnimal">
    <div id="MonCompte">

        <p id="TxtParametres"> MON ANIMAL</p>
        <p id="TxtMonCompte"> {{this.Commande.animal.nom}}</p>

        <div class="ContainerLabelInput">
            <label class="label" for="Nom">Nom</label>
            <input class="InputForm" type="text" id="Nom" name="Nom" [(ngModel)]="this.Commande.animal.nom">
        </div>
        <div class="ContainerLabelInput">
            <label class="label" for="Race">Catégorie </label>
            <kendo-dropdownlist [filterable]="true" class="InputForm" valueField="id" textField="nom"
                [data]="CategoriesAnimal" [(ngModel)]="this.Commande.animal.categorieAnimal"></kendo-dropdownlist>
        </div>

        <div id="ContainerDescription">
            <kendo-label class="k-checkbox-label" [for]="isOkMemorial"
                text="Souhaitez-vous publier votre animal sur le mémorial"></kendo-label>
            <kendo-switch #isOkMemorial [onLabel]="'Oui'" [offLabel]="'Non'"
                [(ngModel)]="this.Commande.isOkMemorial"></kendo-switch>
        </div>
        <div id="ContainerDescription">
            <label class="label" for="Description">Laisser un hommage sur le mémorial en ligne</label>
            <kendo-textarea [rows]="5" class="InputForm" id="Description" name="Description"
                [(ngModel)]="this.Commande.animal.description"></kendo-textarea>
        </div>
        <br>
        <p>Vous pouvez retrouver le mémorial de {{this.Commande.animal.nom}} en cliquant sur ce <a target="_blank"
                [href]="'https://www.monphoenix.fr/memorial/' + this.Commande.idPostWordpress"
                id="LienMemorial">lien</a></p>
        <br><br>
        <div *ngIf="this.messageErreurInformation != ''" class="alert alert-danger">
            {{messageErreurInformation}}
        </div>
        <div *ngIf="this.messageSucces != ''" class="alert alert-success">
            {{messageSucces}}
        </div>
        <button id="BtnEnregistrer" (click)="EnregistrerFicheAnimal()">SAUVEGARDER <span> ➞</span></button>
    </div>
    <div id="Animal">

        <div id="ContainerMonAnimal">
            <div id="ContainerBtnAnimal">
                <div id="ImgBackground"></div>
                <input type="file" accept="image/jpeg,image/png" id="fileInput" (change)="select($event)"
                    style="display: none;">
                <p id="TxtPrevention">Attention, cette image sera publiée en ligne</p>
                <p id="TxtAjoutPhoto">Ajouter une photo</p>
                <img *ngIf="this.Commande.animal.photo1 == ''" id="ImgAnimal" (click)="AfficherChoixImage()"
                    src="../../../../assets/Utilisateur/LogoAjouterImageMemorialFiche.png" alt="PhotoAnimal">
                <img *ngIf="this.Commande.animal.photo1 != ''" id="ImgAnimal" (click)="AfficherChoixImage()"
                    [src]="this.Commande.animal.photo1" alt="PhotoAnimal">
            </div>
        </div>


    </div>
</div>