<div id="Footer">
    <div id="ContainerFooter">
        <img id="LogoFooter" src="../../../assets/Connexion/LogoPhoenixTexte.png">
        <div id="ContainerCoordonnes">
            <p>2 rue Dion Bouton</p>
            <p>44116 VIEILLEVIGNE</p>
            <p>09 70 19 30 27</p>
        </div>
        <button id="BtnFooterContact" (click)="GoToSite('https://www.monphoenix.fr/contact/')">Nous contacter</button>
    </div>
    <div id="MentionsLegales">
        <p class="LienClickable" (click)="GoToSite('https://www.monphoenix.fr/')">Mon Phoenix</p>
        <p class="LienClickable" (click)="GoToSite('https://www.monphoenix.fr/rgpd/')">Politique de confidentialité</p>
        <p class="LienClickable" (click)="GoToSite('https://www.monphoenix.fr/contact/')">Contact</p>
        <p class="LienClickable" (click)="GoToSite('https://www.monphoenix.fr/mentions-legales/')">Mentions légales</p>
    </div>
</div>