import { Component, OnInit } from '@angular/core';
import { Commande } from '../../../_models/Commande';
import { CommandeService } from '../../../_services/CommandeService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-technicien-fournee-accueil',
  templateUrl: './technicien-fournee-accueil.component.html',
  styleUrls: ['./technicien-fournee-accueil.component.scss']
})
export class TechnicienFourneeAccueilComponent implements OnInit {

  Commandes: Commande[] = []

  constructor(private _CommandeService: CommandeService,private _router:Router) { }

  async ngOnInit(): Promise<void> {
    this.Commandes = (await this._CommandeService.GetCommandeByStatutId(4)).objet

    this.Commandes.forEach(x => {
      x.animal.dateDeces = new Date(x.animal.dateDeces)
      x.nbJourDeces = Math.round((new Date().getTime() - x.animal.dateDeces.getTime()) / (1000 * 3600 * 24));
      x.animal.dateArrivee = new Date(x.asso_StatutCommande_Commande.filter(x => x.statutCommandeId == 3)[0].date)
    })
  }

  public CreationFournee(){
    this._router.navigate(['/Technicien/fourneecreation'])
  }


}
