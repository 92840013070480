<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <form #infoForm="ngForm" name="Form">
                <div *ngIf="this.messageErreurInformation != ''" class="alert alert-danger">
                    {{messageErreurInformation}}
                </div>
                <kendo-tabstrip (tabSelect)="onSelect($event)" #tabstrip>
                    <kendo-tabstrip-tab title="Propriétaire" [selected]="true" #tabInformation>
                        <ng-template kendoTabContent>
                            <div *ngIf="!this.nouveau && this._GlobalService.isAdmin()"
                                class="ContainerArchiveCommande">
                                <div class="row">
                                    <div class="col-md-12">
                                        <kendo-label class="k-checkbox-label" [for]="archiver"
                                            text="Commande archivée"></kendo-label>
                                        <input type="checkbox" name="archiver" [(ngModel)]="this.commande.archiver"
                                            #archiver kendoCheckBox />
                                    </div>
                                </div>
                            </div>
                            <br>
                            <h2>Informations du Propriétaire</h2>
                            <br>
                            <div class="row">
                                <div class="col-md-6">
                                    <kendo-label class="k-form" text="Nom *">
                                        <kendo-textbox placeholder="Nom" name="nom"
                                            [(ngModel)]="this.commande.client.nom" [clearButton]="false" required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                                <div class="col-md-6">
                                    <kendo-label class="k-form" text="Prénom *">
                                        <kendo-textbox placeholder="Prénom" name="prenom"
                                            [(ngModel)]="this.commande.client.prenom" [clearButton]="false" required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-6">
                                    <kendo-label class="k-form" text="Email">
                                        <kendo-textbox placeholder="Email" name="email"
                                            [(ngModel)]="this.commande.client.email" [clearButton]="false" required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                                <div class="col-md-6">
                                    <kendo-label class="k-form" text="Téléphone *">
                                        <kendo-textbox placeholder="Téléphone" name="telephone"
                                            [(ngModel)]="this.commande.client.telephone" [clearButton]="false" required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-4">
                                    <kendo-label class="k-form" text="Adresse *">
                                        <kendo-textbox placeholder="Adresse" name="Adresse"
                                            [(ngModel)]="this.commande.client.adresse" [clearButton]="false" required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                                <div class="col-md-4">
                                    <kendo-label class="k-form" text="Code postal *">
                                        <kendo-textbox placeholder="Code postal" name="codePostal"
                                            [(ngModel)]="this.commande.client.codePostal" [clearButton]="false"
                                            required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                                <div class="col-md-4">
                                    <kendo-label class="k-form" text="Ville *">
                                        <kendo-textbox placeholder="Ville" name="Ville"
                                            [(ngModel)]="this.commande.client.ville" [clearButton]="false" required>
                                            <ng-template kendoTextBoxSuffixTemplate>
                                                <kendo-icon name="pencil"></kendo-icon>
                                            </ng-template>
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-12">
                                    <kendo-label class="k-form" text="Commentaire interne :">
                                        <kendo-textarea placeholder="Commentaire"
                                            [(ngModel)]="this.commande.client.commentaire" [rows]="5"
                                            name="commentaire">
                                        </kendo-textarea>
                                    </kendo-label>
                                </div>
                            </div>

                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="Animal" [disabled]="!IsSuivantValide()" [selected]="false"
                        #tabInformation>
                        <ng-template kendoTabContent>
                            <br>
                            <h2>Informations de l'animal</h2>
                            <br>
                            <br>
                            <div class="BodureBas">

                                <div class="row">
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Nom *">
                                            <kendo-textbox placeholder="Nom" name="nom"
                                                [(ngModel)]="this.commande.animal.nom" [clearButton]="false" required>
                                                <ng-template kendoTextBoxSuffixTemplate>
                                                    <kendo-icon name="pencil"></kendo-icon>
                                                </ng-template>
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Date de décès *">
                                            <kendo-datepicker required [(ngModel)]="this.commande.animal.dateDeces"
                                                name="dateDeces"></kendo-datepicker>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Espèce *">
                                            <kendo-dropdownlist required valueField="nom" textField="nom"
                                                name="categorieAnimal" [data]="CategoriesAnimal"
                                                [(ngModel)]="this.commande.animal.categorieAnimal" required
                                                [filterable]="true">
                                            </kendo-dropdownlist>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Sexe *">
                                            <kendo-dropdownlist required name="sexe" [data]="Sexes"
                                                [(ngModel)]="this.commande.animal.sexe" required>
                                            </kendo-dropdownlist>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Poids (Kg)*"></kendo-label>
                                        <kendo-numerictextbox [format]="'n2'" name="poids" [min]="0" [step]="0.1"
                                            required [(ngModel)]="this.commande.animal.poids">
                                        </kendo-numerictextbox>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Cause décès *">
                                            <kendo-dropdownlist required name="CauseDeces" [data]="AllCausesDecesAnimal"
                                                [(ngModel)]="commande.animal.causeDecesAnimal" required valueField="id"
                                                textField="nom">
                                            </kendo-dropdownlist>
                                        </kendo-label>
                                    </div>
                                    <div class="col-md-4">
                                        <kendo-label class="k-form" text="Identification">
                                            <kendo-textbox placeholder="identification" name="identification"
                                                [(ngModel)]="this.commande.animal.identification" [clearButton]="false">
                                            </kendo-textbox>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                                <div class="row" *ngIf="this._GlobalService.isAdmin()">
                                    <div class="col-md-6">
                                        <kendo-label class="k-checkbox-label" [for]="isAnimalApporteDirectement"
                                            text="L'animal à été directement apporté par le propriétaire"></kendo-label>
                                        <input type="checkbox" name="isAnimalApporteDirectement"
                                            [(ngModel)]="this.commande.isAnimalApporteDirectement"
                                            #isAnimalApporteDirectement kendoCheckBox />
                                    </div>
                                </div>
                                <br>
                            </div>
                            <br>
                            <div>
                                <br>
                                <h2>Informations du cabinet</h2>
                                <br>
                                <div class="row" *ngIf="this._GlobalService.isAdmin()">
                                    <div class="col-md-6">
                                        <kendo-label class="k-checkbox-label" [for]="isAnimalsuiviCabinet"
                                            text="L'animal est suivi par un cabinet"></kendo-label>
                                        <input type="checkbox" name="isAnimalsuiviCabinet"
                                            [(ngModel)]="this.commande.isAnimalsuiviCabinet" #isAnimalsuiviCabinet
                                            kendoCheckBox />
                                    </div>
                                </div>
                                <br>
                                <div class="row" *ngIf="this.commande.isAnimalsuiviCabinet">
                                    <div class="col-md-6">
                                        <kendo-label class="k-form" text="Etablissement vétérinaires *">
                                            <kendo-dropdownlist name="siteCabinetVeterinaire" textField="CabinetVille"
                                                valueField="CabinetVille" [data]="EtablissementVeterinaires"
                                                [(ngModel)]="this.commande.siteCabinet" required [filterable]="true"
                                                (filterChange)="handleFilterEtablissementveterinaire($event)"
                                                (selectionChange)="UpdateListeSac($event)">
                                            </kendo-dropdownlist>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-12">
                                        <kendo-label class="k-form" text="Commentaire interne :">
                                            <kendo-textarea [(ngModel)]="this.commande.animal.commentaire" [rows]="5"
                                                name="commentaire">
                                            </kendo-textarea>
                                        </kendo-label>
                                    </div>
                                </div>
                                <br>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="Crémation" [disabled]="!IsSuivantValideCremation()" [selected]="false"
                        #tabInformation>
                        <ng-template kendoTabContent>
                            <br>
                            <h2>Informations sur la crémation</h2>
                            <br>
                            <div class="row" *ngIf="this._GlobalService.isVeterinaire()">

                                <div class="col-md-3">
                                    <kendo-label class="k-form" [text]="lblTypeCremation">
                                        <kendo-dropdownlist *ngIf="isCommandeModifiableVeterinaire()" required
                                            valueField="nom" textField="nom" [data]="TypesCremation"
                                            [(ngModel)]="this.commande.detailCremation.typeCremationInitial"
                                            name="typeCremationInitial">
                                        </kendo-dropdownlist>

                                        <kendo-textbox *ngIf="!isCommandeModifiableVeterinaire()" [readonly]="true"
                                            required
                                            [(ngModel)]="this.commande.detailCremation.typeCremationInitial.nom"
                                            name="nom"></kendo-textbox>

                                    </kendo-label>
                                </div>
                                <div *ngIf="!this.nouveau && this.commande.detailCremation.sac.typeSacCremation != null"
                                    class="col-md-3">
                                    <kendo-label class="k-form" text="Taille de la housse">
                                        <kendo-textbox placeholder="Taille du housse" name="TailleSac"
                                            [(ngModel)]="this.commande.detailCremation.sac.typeSacCremation.nom"
                                            [clearButton]="false" required [readonly]="true">
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                                <div class="col-md-3">
                                    <kendo-label *ngIf="isCommandeModifiable()" class="k-form"
                                        text="Numéro d'étiquette *">
                                        <kendo-dropdownlist name="id" textField="id" valueField="id" [data]="filteredSacs"
                                            [(ngModel)]="this.commande.detailCremation.sac"  (filterChange)="onFilter($event)" required
                                            [filterable]="true">
                                        </kendo-dropdownlist>
                                    </kendo-label>
                                    <kendo-label *ngIf="!isCommandeModifiable()" class="k-form"
                                        text="Numéro d'étiquette *">
                                        <kendo-textbox [readonly]="true" required
                                            [(ngModel)]="this.commande.detailCremation.sac.id"
                                            name="id"></kendo-textbox>
                                    </kendo-label>
                                </div>
                            </div>

                            <div class="row" *ngIf="this._GlobalService.isAdmin()">
                                <div class="col-md-3">
                                    <kendo-label class="k-form" [text]="lblTypeCremation">
                                        <kendo-textbox [readonly]="true" required *ngIf="!this.nouveau"
                                            [(ngModel)]="this.commande.detailCremation.typeCremationInitial.nom"
                                            name="typeCremationInitial"></kendo-textbox>

                                        <kendo-dropdownlist *ngIf="this.nouveau" required valueField="nom"
                                            textField="nom" [data]="TypesCremation"
                                            [(ngModel)]="this.commande.detailCremation.typeCremationInitial"
                                            name="typeCremationInitial">
                                        </kendo-dropdownlist>
                                    </kendo-label>
                                </div>
                                <div class="col-md-3" *ngIf="!this.nouveau">
                                    <div *ngIf="isCommandeModifiable()">
                                        <kendo-label class="k-form" text="Type de crémation final*">
                                            <kendo-dropdownlist required valueField="nom" textField="nom"
                                                [data]="TypesCremation"
                                                [(ngModel)]="this.commande.detailCremation.typeCremationFinal"
                                                name="typeCremationFina">
                                            </kendo-dropdownlist>
                                        </kendo-label>
                                    </div>
                                    <div *ngIf="!isCommandeModifiable()">
                                        <kendo-label class="k-form" text="Type de crémation final*">
                                            <kendo-textbox [readonly]="true" required
                                                [(ngModel)]="this.commande.detailCremation.typeCremationFinal.nom"
                                                name="typeCremationFinal"></kendo-textbox>
                                        </kendo-label>
                                    </div>
                                </div>
                                <div *ngIf="!this.nouveau && this.commande.detailCremation.sac.typeSacCremation != null"
                                    class="col-md-3">
                                    <kendo-label class="k-form" text="Taille de la housse">
                                        <kendo-textbox placeholder="Taille du housse" name="TailleSac"
                                            [(ngModel)]="this.commande.detailCremation.sac.typeSacCremation.nom"
                                            [clearButton]="false" required [readonly]="true">
                                        </kendo-textbox>
                                    </kendo-label>
                                </div>
                                <div class="col-md-3">
                                    <kendo-label *ngIf="isCommandeModifiable()" class="k-form"
                                        text="Numéro d'étiquette *">
                                        <kendo-dropdownlist name="id" textField="id" valueField="id" [data]="filteredSacs"
                                            [(ngModel)]="this.commande.detailCremation.sac"  (filterChange)="onFilter($event)" required
                                            [filterable]="true">
                                        </kendo-dropdownlist>
                                    </kendo-label>
                                    <kendo-label *ngIf="!isCommandeModifiable()" class="k-form"
                                        text="Numéro d'étiquette *">
                                        <kendo-textbox [readonly]="true" required
                                            [(ngModel)]="this.commande.detailCremation.sac.id"
                                            name="id"></kendo-textbox>
                                    </kendo-label>
                                </div>


                            </div>
                            <br>
                            <div *ngIf="this.commande.statutCommandeErreur != null">
                                <h3>Erreur</h3>
                                <div class="alert alert-danger">
                                    {{this.commande.statutCommandeErreur.nom}}
                                </div>

                            </div>
                            <br>
                            <div *ngIf="this.commande.asso_StatutCommande_Commande.length > 0 && !this.nouveau ">
                                <div id="AffecterStatut" *ngIf="!this._GlobalService.isVeterinaire()">
                                    <button (click)="AjouterStatut()" kendoButton themeColor="primary">Affecter un
                                        statut</button>
                                    <br>
                                    <div id="ContainerStatutAjout" *ngIf="this.IsAjouterStatut">
                                        <br>
                                        <br>
                                        <div id="ContainerStatut">
                                            <kendo-dropdownlist id="DrpdwnStatuts" [(ngModel)]="StatutSelect"
                                                [data]="AllStatuts" valueField="id" textField="nom"
                                                name="StatutSelect"></kendo-dropdownlist>
                                            <br>
                                            <br>
                                            <button (click)="AffecterStatut()" kendoButton
                                                themeColor="tertiary">Enregistrer le statut</button>
                                        </div>
                                    </div>
                                </div>

                                <br>
                                <h2>Suivi de la commande</h2>
                                <div *ngIf="this.messageErreurInformationSupprimerEtat != ''"
                                    class="alert alert-danger">
                                    {{messageErreurInformationSupprimerEtat}}
                                </div>
                                <kendo-grid [data]="gridData" (dataStateChange)="dataStateChange($event)"
                                    [sortable]="true" [sort]="gridState.sort" [columnMenu]="true"
                                    [pageSize]="gridState.take" [skip]="gridState.skip" [height]="400" [pageable]="true"
                                    (edit)="SupprimerEtat($event)">
                                    <!-- (edit)="RetirerEtatCommande($event.dataItem)" -->
                                    <kendo-grid-column field="date" title="Date">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            {{dataItem.date | date:'dd/MM/yyyy HH:mm'}}
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column field="statutCommande.nom" title="Statut"></kendo-grid-column>
                                    <kendo-grid-column title="Action">
                                        <ng-template let-dataItem kendoGridCellTemplate>
                                            <button themeColor="primary"
                                                *ngIf="this.isAdministrateur() && isStatutRetirable(dataItem) "
                                                kendoGridEditCommand>Supprimer</button>
                                        </ng-template>
                                    </kendo-grid-column>
                                </kendo-grid>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </form>
        </div>
    </div>
</div>
<app-loader *ngIf="loading"></app-loader>
<ng-template #dialogActions>
</ng-template>
<ng-container #detailCompteContainer></ng-container>
<div kendoDialogContainer></div>