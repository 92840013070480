<div class="ContainerAll">
    <div class="LogoHautChat">
        <img src="../../assets/Connexion/chat.png" alt="logoChat">
    </div>
    <div class="LogoPhoenixMilieu">
        <img src="https://www.monphoenix.fr/wp-content/uploads/2024/11/logo-blanc.png" alt="logoChat">
    </div>
    <form id="form" class="login-form">

        <div class="row">
            <p>Bienvenue sur la plateforme MonPhoenix</p>

        </div>
        <div class="row">
            <kendo-label class="lblTxt" text="Code" for="Code"></kendo-label>
            <kendo-textbox id="Code" [(ngModel)]="DataClient.code" name="Code" required></kendo-textbox>
        </div>
        <br>
        <p *ngIf="this.MessageErreur !='' && this.MessageErreur !='Le lien est expiré, veuillez en demander un nouveau'" class="alert alert-danger" style="background-color: #102053; color: white;">
            {{MessageErreur}}</p>
        <div class="row" *ngIf="this.MessageErreur !='Le lien est expiré, veuillez en demander un nouveau'">
            <kendo-button type="submit" id="btnConnexion" (click)="onSubmit()">Authentifier</kendo-button>
        </div>
        <br>
        <div class="row" *ngIf="this.MessageErreur =='Le lien est expiré, veuillez en demander un nouveau'">
            <p class="alert alert-danger" style="background-color: #102053; color: white;">Le lien est expiré, veuillez
                en demander un nouveau en cliquant <span style="text-decoration: underline; cursor: pointer;" (click)="DemanderNouveauLien()">ici</span></p>
            <kendo-button type="submit" id="btnConnexion" (click)="DemanderNouveauLien()">Demander un nouveau
                lien</kendo-button>
        </div>

        <p *ngIf="this.MessageErreurRenouvellement !=''" class="alert alert-danger"
            style="background-color: #102053; color: white;">
            {{MessageErreurRenouvellement}}</p>
        <p *ngIf="this.MessageSuccesRenouvellement !=''" class="alert alert-danger"
            style="background-color: #102053; color: white;">
            {{MessageSuccesRenouvellement}}</p>

        <br>


    </form>
    <div class="LogoBasChien">
        <img src="../../assets/Connexion/chien.png" alt="LogoChien">
    </div>
</div>

<ngx-spinner bdColor="rgba(197, 191, 194, 0.8)" size="default" color="red" type="ball-clip-rotate"></ngx-spinner>