<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <form #infoForm="ngForm" name="Form">

                <div *ngIf="!this.nouveau" class="ContainerArchiveCommande">
                    <div class="row">
                        <div class="col-md-12">
                            <kendo-label class="k-checkbox-label" [for]="archiver"
                                text="Tournée archivée"></kendo-label>
                            <input type="checkbox" name="archiver" [(ngModel)]="this.Tournee.archiver"
                                #archiver kendoCheckBox />
                        </div>
                    </div>
                </div>

                <h2>Informations la tournée</h2>
                <br><br>
                <div *ngIf="!this.verifFormInformation" class="alert alert-danger">
                    {{messageErreurInformation}}
                </div>
                <div class="row BorderBottom">
                    <div class="col-md-6">
                        <kendo-label class="k-form" text="Date de la tournée *">
                            <kendo-datepicker required [(ngModel)]="this.Tournee.date" name="date"></kendo-datepicker>
                        </kendo-label>
                    </div>
                    <div class="col-md-6">
                        <kendo-label class="k-form" text="Nom de la tournée *">
                            <kendo-textbox required [(ngModel)]="this.Tournee.nom" name="nom"></kendo-textbox>
                        </kendo-label>
                    </div>
                </div>
                <br>
                
                <h2>Liste des établissement</h2>
                <br>
                <div class="row">
                    <div class="col-12">
                        <kendo-label class="k-form" text="Etablissement vétérinaires *">
                            <kendo-dropdownlist [(ngModel)]="this.SelectedEtablissement" name="SelectedEtablissement" textField="CabinetVille"
                                valueField="CabinetVille" [data]="SitesVeterinaires" required
                                (selectionChange)="AjouterEtablissementTournee($event)" [filterable]="true"
                                (filterChange)="handleFilterEtablissementveterinaire($event)">
                            </kendo-dropdownlist>
                        </kendo-label>
                    </div>
                </div>
                <br>
                <p>Poids total de la tournée : <b>{{totalPoidsTournee}}</b> Kg</p>
                <p>Nombres de corps à ramasser : <b>{{totalNbCorps}}</b></p>

                <div class="row">
                    <div class="col-12">
                        <div class="grid-wrapper">
                            <kendo-grid [data]="gridData" (dataStateChange)="dataStateChange($event)"
                                (edit)="SupprimerSite($event)" [sortable]="true" [sort]="gridState.sort"
                                [columnMenu]="true" [pageSize]="gridState.take" [skip]="gridState.skip" [height]="500"
                                [pageable]="true" [filter]="gridState.filter" [filterable]="true">
                                <kendo-grid-column title="Ordre">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <p> {{this.Tournee.tourneeEtablissements.indexOf(dataItem)+1}}</p>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="siteCabinetVeterinaire.CabinetVille" title="Nom">
                                </kendo-grid-column>
                                <kendo-grid-column field="siteCabinetVeterinaire.totalPoids" title="Poids total des corps (Kg)">
                                </kendo-grid-column>
                                <kendo-grid-column field="siteCabinetVeterinaire.nbCorps" title="Nombre de corps à ramasser">
                                </kendo-grid-column>
                                <kendo-grid-column title="Action">
                                    <ng-template kendoGridCellTemplate>
                                        <button themeColor="primary" kendoGridEditCommand>Supprimer de la tournée</button>
                                    </ng-template>
                                </kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<app-loader *ngIf="loading"></app-loader>