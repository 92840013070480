import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-confimer-suppression-commande-view',
  templateUrl: './confimer-suppression-commande-view.component.html',
  styleUrls: ['./confimer-suppression-commande-view.component.scss']
})
export class ConfimerSuppressionCommandeViewComponent {
  @Output() confirmer = new EventEmitter<void>();
  @Output() annuler = new EventEmitter<void>();

  Confirmer() {
    this.confirmer.emit();
  }

  Annuler() {
    this.annuler.emit();
  }
}
