import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../../_services/ClientService';
import { Client } from '../../../_models/Client';
import { Commande } from '../../../_models/Commande';

@Component({
  selector: 'app-mon-compte-memorial',
  templateUrl: './mon-compte-memorial.component.html',
  styleUrls: ['./mon-compte-memorial.component.scss']
})
export class MonCompteMemorialComponent implements OnInit {

  loading: boolean = false;
  Client: Client = new Client();
  MessageErreur: string = "";
  MessageSucces: string = "";

  constructor(private _ClientService: ClientService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    //Aller chercher les infos du client
    this.Client = (await this._ClientService.GetInformations()).objet

    this.Client.commandes[this.Client.commandes.length -1].animal.dateDeces = new Date(this.Client.commandes[this.Client.commandes.length -1].animal.dateDeces)

    this.loading = false;
  }

  async UpdateClient() {
    this.loading = true;
    this.MessageErreur = ""
    this.MessageSucces = ""
    //Vérifier que les champs soit bien complétés
    if (this.Client.nom == "" || this.Client.prenom == "" || this.Client.telephone == "" || this.Client.email == ""
      || this.Client.adresse == "" || this.Client.ville == "" || this.Client.codePostal == "") {
      this.MessageErreur = "Veuillez remplir tous les champs"

    } else {
      //Set le role sinon la requête ne fonctionne pas
      this.Client.role = ""
      this.Client.commandes[0].client.role = ""

      //Faire la requête
      let reponse = await this._ClientService.ModifierClient(this.Client);

      if (reponse.statusCode == 200) {
        this.MessageSucces = "Vos informations ont été mises à jour"
      } else {
        this.MessageErreur = reponse.messageException
      }
    }

    this.loading = false;

  }

  GoToFicheAnimal(commande: Commande) {
    location.href = "client/moncompte/animal/" + commande.id
  }


}
