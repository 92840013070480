import { Injectable } from '@angular/core';
import { ComponentRef, ApplicationRef, Injector, ComponentFactoryResolver } from '@angular/core';
import { ConfirmerPanierViewComponent } from '../Client/_Views/confirmer-panier-view/confirmer-panier-view.component';


@Injectable({
  providedIn: 'root'
})
export class PopupService {
  private popupRef: ComponentRef<ConfirmerPanierViewComponent> | null = null;

  constructor(
    private appRef: ApplicationRef,
    private injector: Injector,
    private resolver: ComponentFactoryResolver
  ) {}

  showPopup(onContinue: () => void, onGoToCart: () => void) {
    if (this.popupRef) return; // Empêche d’afficher plusieurs popups

    const factory = this.resolver.resolveComponentFactory(ConfirmerPanierViewComponent);
    this.popupRef = factory.create(this.injector);

    this.popupRef.instance.continue.subscribe(() => {
      onContinue();
      this.closePopup();
    });

    this.popupRef.instance.goToCart.subscribe(() => {
      onGoToCart();
      this.closePopup();
    });

    this.appRef.attachView(this.popupRef.hostView);
    document.body.appendChild(this.popupRef.location.nativeElement);
  }

  closePopup() {
    if (this.popupRef) {
      this.appRef.detachView(this.popupRef.hostView);
      this.popupRef.destroy();
      this.popupRef = null;
    }
  }
}
