import { Component, ElementRef, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { State, process } from '@progress/kendo-data-query';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { GlobalService } from '../../_services/GlobalService';
import { CommandeService } from '../../_services/CommandeService';
import { Commande } from '../../_models/Commande';
import { CommandeViewComponent } from '../_Views/commande-view/commande-view.component';
import { EnumStatutCommande } from '../../_models/Filter/FilterCommande';
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { CommandeArticleViewComponent } from '../_Views/commande-article-view/commande-article-view.component';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { HttpClient } from "@angular/common/http";
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { PopupServiceConfirmerSuppressionCommande } from '../../_services/PopupServiceConfirmerSuppressionCommande';
@Component({
  selector: 'app-commandes',
  templateUrl: './commandes.component.html',
  styleUrls: ['./commandes.component.scss']
})
export class CommandesComponent {
  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  public dialog !: DialogRef;

  @ViewChild('dialogActionsCommandeArticle') dialogActionsCommandeArticle!: TemplateRef<unknown>;

  @ViewChild('content', { static: false }) content!: ElementRef;

  @ViewChild("detailCompteContainer", { read: ViewContainerRef })
  public detailCompteContainer!: ViewContainerRef;
  @ViewChild('tabstrip') ts!: TabStripComponent;
  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 50,
  };
  loading: boolean = false;
  afficherCommandeArchiver: boolean = false;
  Commandes: Commande[] = new Array()
  CommandesArchivees: Commande[] = new Array()
  valeursFiltre: string[] = new Array()



  public constructor(private _CommandeService: CommandeService,
    public _GlobalService: GlobalService,
    private _dialogService: DialogService,
    private http: HttpClient,
    private popupService: PopupServiceConfirmerSuppressionCommande
  ) { }

  async ngOnInit(): Promise<void> {
    //charge le tableau des utilisateurs
    this.listeCommandesNonArchivees();
  }

  public async listeCommandesNonArchivees() {
    this.loading = true;

    this.Commandes = (await this._CommandeService.GetAllCommandeRaccourci(EnumStatutCommande.NonArchive)).objet;

    this.ProcessCommande()
  }

  public async listeCommandesArchivees() {
    this.loading = true;

    this.Commandes = (await this._CommandeService.GetAllCommandes(EnumStatutCommande.Archive)).objet;

    this.ProcessCommande()
  }

  ProcessCommande() {
    this.Commandes = this.Commandes.map(commande => ({
      ...commande,
      PrenomNom: `${commande.client.prenom} ${commande.client.nom}`,
      dateSouscription: new Date(commande.dateSouscription),
      lblCommandeProduits: commande.asso_Commande_Produits.map(x => `${x.produit.id}-${x.produit.nom}`).join('\n'),
    }));

    //Faire les commandes de type crémation
    this.Commandes
      .filter(x => x.typeCommandeId == 2)
      .forEach(commande => {
        commande.siteCabinet.CabinetVille = commande.siteCabinet.cabinetVeterinaire.nom + " - " + commande.siteCabinet.ville,
          commande.txtAsso_StatutCommande_Commande = commande.asso_StatutCommande_Commande
            .sort((a, b) => {
              return - (a.statutCommandeId - b.statutCommandeId)
            })[0].statutCommande.nom
      }
      );

    //Ajuster les libellé de la commande 
    this.Commandes.forEach(commande => {
      if (commande.typeCommandeId === 1) {
        // Pour les commandes type 1
        commande.lblCommandeProduitsText = commande.lblCommandeProduits.replace(/\n/g, " ");
      } else if (commande.typeCommandeId === 2) {
        // Pour les commandes type 2
        if (this._GlobalService.isAdmin()) {
          commande.lblCommandeProduitsText = commande.detailCremation.typeCremationFinal.nom;
        } else if (this._GlobalService.isVeterinaire()) {
          commande.lblCommandeProduitsText = commande.detailCremation.typeCremationInitial.nom;
        }
      } else {
        commande.lblCommandeProduitsText = ""; // Valeur par défaut si aucune condition n'est remplie
      }
    });


    //Faire les commandes de type article
    this.Commandes = this.Commandes
      .filter(x => (x.typeCommandeId == 1 && x.isPayer) || x.typeCommandeId == 2)
      .map(commande => {
        if (commande.typeCommandeId == 1 && commande.isPayer) {
          return {
            ...commande, // Copie l'objet original
            txtAsso_StatutCommande_Commande: "Payé" // Ajoute ou modifie la propriété
          };
        }
        return commande; // Ne modifie pas la commande si elle est de type 2
      });

    this.Commandes.sort((a, b) => new Date(b.dateSouscription).getTime() - new Date(a.dateSouscription).getTime())

    this.dataStateChange(this.gridState);
    this.loading = false;
  }

  getDefaultTextFilter() {
    return { operator: 'contains', value: this.valeursFiltre };
  }

  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }

  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.Commandes, this.gridState);
  }

  public ouvreDetailCommande(commande: Commande, nouveau: boolean, isCommandeArticle: boolean) {
    this.dialog = this._dialogService.open({
      title: "Fiche convention",
      width: '90%',
      height: '90%',
      //actions: [cancelAction, saveAction],
      actions: isCommandeArticle ? this.dialogActions : this.dialogActionsCommandeArticle,
      appendTo: this.detailCompteContainer,
      closeTitle: "Fermer",
      content: isCommandeArticle ? CommandeViewComponent : CommandeArticleViewComponent,
    });

    let DetailClient = this.dialog.content.instance;
    DetailClient.commande = commande;
    DetailClient.nouveau = nouveau;
  }

  //ajouter un nouveau client
  public AjouterCommande() {
    this.ouvreDetailCommande(new Commande(), true, true);
  }

  //ferme la boite de dialogue
  public closeClient(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  async modifieCompte(dataItem: Commande) {
    this.loading = true;

    //Aller chercher toutes les informations de la commande

    if (dataItem.typeCommandeId == 2) {
      dataItem = await (await this._CommandeService.GetCommandeBySacId(dataItem.detailCremation.sacId)).objet
    } else {
      let IdCommande: number[] = [dataItem.id]
      dataItem = await (await this._CommandeService.GetCommandeByIds(IdCommande)).objet[0]
    }

    this.loading = false;
    dataItem.typeCommandeId == 1 ? this.ouvreDetailCommande(dataItem, false, false) : this.ouvreDetailCommande(dataItem, false, true)
  }

  SuivantCommande() {
    (this.dialog.content.instance as CommandeViewComponent).SuivantCommande();
  }

  public async enregistreClient() {

    let DetailClient: CommandeViewComponent = this.dialog.content.instance;

    if (DetailClient.validate() && await DetailClient.EnregistrerCommande()) {

      this.loading = true;

      await this.listeCommandesNonArchivees();

      this.closeClient();

      this.loading = false;
    }
  }

  AfficherCommandeArchiver(): void {
    this.afficherCommandeArchiver = !this.afficherCommandeArchiver

    if (this.afficherCommandeArchiver) {
      //Aller chercher les commandes archivées
      this.listeCommandesArchivees();
    } else {
      this.listeCommandesNonArchivees();
    }

    //afficher les commandes qui ne sont pas archivées
    this.dataStateChange(this.gridState);

  }

  async ImprimerFicheConvention(dataItem: any) {
    this.loading = true;

    let commande = dataItem as Commande;

    // Aller chercher en détail les informations de la commande
    commande = (await this._CommandeService.GetCommandeBySacId(commande.detailCremation.sacId)).objet;

    let url = "assets/FicheConvention/Convention.html"

    // Charger le fichier HTML
    let html = await lastValueFrom(this.http.get(url, { responseType: 'text' }));

    //Remplacer les données du client dans le fichier HTML
    html = this.ReplaceInfosCommande(html, commande);

    // Charger le fichier CSS
    const css = await lastValueFrom(this.http.get('assets/FicheConvention/style.css', { responseType: 'text' }));

    // Appeler la méthode pour générer le PDF
    await this.downloadFile(html!, css!, commande);

    this.loading = false;
  }

  ReplaceInfosCommande(html: string, commande: Commande): string {
    //Infos Client
    html = html.replace("{NomClient}", commande.client.nom)
    html = html.replace("{PrenomClient}", commande.client.prenom)
    html = html.replace("{AdresseClient}", commande.client.adresse)
    html = html.replace("{CPClient}", commande.client.codePostal)
    html = html.replace("{VilleClient}", commande.client.ville)
    html = html.replace("{TelClient}", commande.client.telephone)
    html = html.replace("{MailClient}", commande.client.email)

    //Infos animal
    html = html.replace("{NomAnimal}", commande.animal.nom)
    html = html.replace("{CategorieAnimal}", commande.animal.categorieAnimal.nom)
    html = html.replace("{SexeAnimal}", commande.animal.sexe)
    html = html.replace("{CauseDeces}", commande.animal.causeDecesAnimal.nom)

    //Infos de la convention
    const DateDuJour = new Date().toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    html = html.replace("{Date}", DateDuJour) //Date sur la page 1
    html = html.replace("{DateDuJour}", DateDuJour) //Date sur la dernière page
    html = html.replace("{NumeroConvention}", commande.detailCremation.sacId.toString()) //Date sur la dernière page
    html = html.replace("{NumeroConventionAnimal}", commande.detailCremation.sacId.toString()) //Date sur la dernière page

    const dateDeces = new Date(commande.animal.dateDeces).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    html = html.replace("{DateDeces}", dateDeces) //Date sur la dernière page

    //Cocher les checkbox de la cause de décès
    html = this.SetupCheckboxCauseDeces(commande.animal.causeDecesAnimal.id, html)

    if (this._GlobalService.isAdmin()) {
      //Cocher les checkbox de la cause de décès
      html = this.SetupCheckboxCremation(commande.detailCremation.typeCremationFinal.id, html)
    } else {
      //Cocher les checkbox de la cause de décès
      html = this.SetupCheckboxCremation(commande.detailCremation.typeCremationInitial.id, html)
    }

    return html;
  }

  private SetupCheckboxCauseDeces(idCause: number, html: string): string {

    let checkboxId = ""

    switch (idCause) {
      case 1:
        checkboxId = "CheckboxNaturelle"
        break
      case 2:
        checkboxId = "CheckboxAccidentelle"
        break
      case 3:
        checkboxId = "CheckboxMedicale"
        break
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const checkboxToCheck = doc.getElementById(checkboxId) as HTMLInputElement;
    checkboxToCheck.setAttribute("checked", "true");

    return doc.body.innerHTML
  }
  private SetupCheckboxCremation(idTypeCremation: number, html: string): string {

    let checkboxId = ""

    switch (idTypeCremation) {
      case 1:
        checkboxId = "CheckboxCremationCollective"
        break
      case 2:
        checkboxId = "CheckboxCremationIndividuelle"
        break
      case 3:
        checkboxId = "CheckboxCremationPrivee"
        break
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const checkboxToCheck = doc.getElementById(checkboxId) as HTMLInputElement;
    checkboxToCheck.setAttribute("checked", "true");

    return doc.body.innerHTML
  }

  private async downloadFile(html: string, css: string, _commande: Commande) {
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      hotfixes: [], // Fixes pour les bugs connus,
      compress: true
    });

    const element = document.createElement('div');
    element.innerHTML = html;
    element.style.cssText = `
      margin: 0;
      padding: 0;
      font-family: Arial, sans-serif;
      width: 210mm;
      height: 297mm;
      padding: 10mm;
      font-size: 9px;
    `;

    const style = document.createElement('style');
    style.innerHTML = css;

    document.head.appendChild(style);
    document.body.appendChild(element);

    // Récupérer toutes les pages (divs)
    const pages = element.querySelectorAll('.page');

    for (let i = 0; i < pages.length; i++) {
      const canvas = await html2canvas(pages[i] as HTMLElement, {
        scale: 3, // Augmente la qualité
        useCORS: true, // Permet de charger des images depuis une URL distante
        windowWidth: 794, // Correspond à la largeur d'une page A4 à 96 DPI
        windowHeight: 900 // Correspond à la hauteur d'une page A4 à 96 DPI
      });

      const imgData = canvas.toDataURL('image/png');

      const marginLeft = 10; // Marge gauche de 10mm
      const marginTop = 10; // Marge en haut de 10mm

      const pageWidth = pdf.internal.pageSize.getWidth();
      const imgWidth = pageWidth - 2 * marginLeft;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', marginLeft, marginTop, imgWidth, imgHeight);

      if (i < pages.length - 1) {
        pdf.addPage();
      }
    }

    // Ajouter un titre directement après l'ajout des pages et avant d'ouvrir le PDF
    let title = "Convention_" + _commande.client.prenom + "_" + _commande.client.nom + "_" + _commande.animal.nom + ".pdf";

    // Définir les propriétés après la création du PDF
    pdf.setProperties({
      title: title
    });

    // Ouvrir le PDF dans une nouvelle fenêtre avec l'URL Blob
    const pdfBlobUrl = pdf.output('bloburl');
    const printWindow = window.open(pdfBlobUrl, '_blank');
    if (printWindow) {
      printWindow.focus();
    }

    // Nettoyage après génération
    document.body.removeChild(element);
    document.head.removeChild(style);
  }


  public rowClass = (context: any) => {
    let commande = context.dataItem as Commande;
    if (commande.statutCommandeErreur != null) {
      return 'highlight-row'; // Classe CSS personnalisée
    } else {
      return ''; // Pas de style pour les autres lignes
    }
  };

  RefreshConvention() {
    this.listeCommandesNonArchivees()
  }

  async SupprimerCommande(commandeId: number): Promise<void> {
    this.popupService.showPopup(
      () => {
        this.popupService.closePopup()
      },
      async () => {
        let reponse = await this._CommandeService.SupprimerCommande(commandeId)
      }
    );
  }

}
