import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-confirmer-panier-view',
  templateUrl: './confirmer-panier-view.component.html',
  styleUrls: ['./confirmer-panier-view.component.scss']
})
export class ConfirmerPanierViewComponent {
  @Output() continue = new EventEmitter<void>();
  @Output() goToCart = new EventEmitter<void>();

  onContinue() {
    this.continue.emit();
  }

  onGoToCart() {
    this.goToCart.emit();
  }

}
