<app-header-client-boutique *ngIf="!this.IsMobile"></app-header-client-boutique>
<app-header-client-boutique-mobile *ngIf="this.IsMobile"></app-header-client-boutique-mobile>
<div *ngIf="this.messageErreurInformation != ''" class="alert alert-danger">
    {{messageErreurInformation}}
</div>

<div *ngIf="!this.IsMobile">
    <div id="ContainerDetailProduit">
        <h3><span style="cursor: pointer;" (click)="GoToAccueil()">Accueil</span></h3>
        <p id="TxtProduit">PRODUIT</p>
        <p id="TxtNomProduit">{{produit.nom}}</p>
        <div id="ContainerDrpDown" *ngIf="this.IsProduitTailleModifiable()">
            <label [for]="taille" class="TxtDropdown">Taille</label>
            <kendo-dropdownlist valueField="id" textField="taille" #taille rounded="full" id="DrpTaille"
                [(ngModel)]="this.produit.Taille" [data]="this.produit.variationProduitsTailles"></kendo-dropdownlist>
        </div>
        <div id="ContainerDrpDown" *ngIf="IsProduitCouleurModifiable()">
            <label [for]="taille" class="TxtDropdown">Couleur</label>
            <kendo-dropdownlist (selectionChange)="this.UpdatePhotoProduit($event)" valueField="id" textField="couleur"
                #taille rounded="full" id="DrpTaille" [(ngModel)]="CouleurSelect"
                [data]="this.produit.variationProduitsCouleurs"></kendo-dropdownlist>
        </div>
        <div id="ContainerDrpDown" *ngIf="IsProduitQuantiteModifiable()">
            <label [for]="quantite" text="Quantité">Quantité</label>
            <kendo-dropdownlist #quantite rounded="full" id="DrpQuantite" [data]="Quantite"
                [(ngModel)]="QuantiteSelect"></kendo-dropdownlist>
        </div>
        <div id="ContainerPrixPanier">
            <span id="TxtPrix">{{this.getPrixProduit()}} €</span>

            <div *ngIf="this.produit.id != 845" class="addtocart" (click)="AjouterPanier()">
                <div class="pretext" *ngIf="this.produit.id !== 844">
                    <i class="fas fa-cart-plus"></i>
                    <p>AJOUTER AU PANIER</p>
                </div>

                <div class="pretext" *ngIf="this.produit.id == 844">
                    <i class="fas fa-cart-plus"></i>
                    <p>CHANGER AVEC CETTE OFFRE</p>
                </div>

                <div class="pretext done">
                    <div class="posttext"><i class="fas fa-check"></i> AJOUTÉ</div>
                </div>
            </div>
        </div>
    </div>

    <div id="ContainerImageProduit">
        <img id="ImageProduit" [src]="getImageProduit()" alt="Image du produit">
    </div>

    <div id="ContainerDescriptionProduit">
        <p id="TxtDescription">Description du produit</p>
        <p id="TxtDescriptionProduit" [innerHTML]="produit.description"></p>
    </div>
    <br>
</div>

<div *ngIf="this.IsMobile" class="ContainerMobile">
    <p id="TxtProduit">PRODUIT</p>

    <p id="TxtNomProduit">{{produit.nom}}</p>

    <div id="ContainerDrpDown" *ngIf="this.IsProduitTailleModifiable()">
        <label [for]="taille" class="TxtDropdown">Taille</label>
        <kendo-dropdownlist valueField="id" textField="taille" #taille rounded="full" id="DrpTaille"
            [(ngModel)]="this.produit.Taille" [data]="this.produit.variationProduitsTailles"></kendo-dropdownlist>
    </div>

    <div id="ContainerDrpDown" *ngIf="IsProduitCouleurModifiable()">
        <label [for]="taille" class="TxtDropdown">Couleur</label>
        <kendo-dropdownlist (selectionChange)="this.UpdatePhotoProduit($event)" valueField="id" textField="couleur"
            #taille rounded="full" id="DrpTaille" [(ngModel)]="CouleurSelect"
            [data]="this.produit.variationProduitsCouleurs"></kendo-dropdownlist>
    </div>

    <div id="ContainerDrpDown" *ngIf="IsProduitQuantiteModifiable()">
        <label [for]="quantite" text="Quantité">Quantité</label>
        <kendo-dropdownlist #quantite rounded="full" id="DrpQuantite" [data]="Quantite"
            [(ngModel)]="QuantiteSelect"></kendo-dropdownlist>
    </div>
    <br>

    <span id="TxtPrix">{{this.getPrixProduit()}} €</span>

    <div *ngIf="this.produit.id != 845" class="addtocart" (click)="AjouterPanier()">
        <div class="pretext" *ngIf="this.produit.id !== 844">
            <i class="fas fa-cart-plus"></i>
            <p>AJOUTER AU PANIER</p>
        </div>

        <div class="pretext" *ngIf="this.produit.id == 844">
            <i class="fas fa-cart-plus"></i>
            <p>CHANGER AVEC CETTE OFFRE</p>
        </div>

        <div class="pretext done">
            <div class="posttext"><i class="fas fa-check"></i> AJOUTÉ</div>
        </div>
    </div>
    <div id="ContainerDescriptionProduit">
        <p id="TxtDescription">Description du produit</p>
        <p id="TxtDescriptionProduit" [innerHTML]="produit.description"></p>
    </div>

    <div id="ContainerImageProduit">
        <img id="ImageProduit" [src]="getImageProduit()" alt="Image du produit">
    </div>

</div>

<p id="TxtCompleterCommande">Complétez votre commande</p>

<div id="ContainerProduits">
    <app-fiche-produit-template [Produits]="produitsRecommandes"></app-fiche-produit-template>
</div>
<br>
<button id="BtnRetourAccueil" (click)="GoToAccueil()">RETOURNER À L'ACCUEIL &#10142;</button>