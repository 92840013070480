import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../../_services/ClientService';
import { Client } from '../../../_models/Client';
import { Commande } from '../../../_models/Commande';

@Component({
  selector: 'app-header-client-mobile',
  templateUrl: './header-client-mobile.component.html',
  styleUrls: ['./header-client-mobile.component.scss']
})
export class HeaderClientMobileComponent implements OnInit {

  public commande: Commande = new Commande()

  constructor(private _ClientService: ClientService) {

  }
  async ngOnInit(): Promise<void> {
    //Aller chercher la commande en cours du client
    this.commande = (await this._ClientService.GetCommandeClient()).objet

    //Si elle n'est pas en cours alors aller chercher la dernière
    if (this.commande == undefined) {
      this.commande = (await this._ClientService.GetLatestCommande()).objet
    }
  }

  GoToAccueil() {
    location.href = "/client/accueil"
  }

  toggleMenu() {
    const menu = document.getElementById("menu")!;
    menu.style.display = menu.style.display === "block" ? "none" : "block";
  }

  async GoToMemorial() {
    //Aller chercher les commandes du client
    let client = ((await this._ClientService.GetInformations()).objet) as Client

    location.href = "/client/moncompte/animal/" + client.commandes[client.commandes.length - 1].id
  }
}