
import { CategorieAnimal } from "./CategorieAnimal";
import { CauseDecesAnimal } from "./CauseDecesAnimal";
import { Client } from "./Client";
import { TypeCremation } from "./TypeCremation";

export class Animal {
    id: number = 0;
    nom: string = "";
    dateDeces: Date = new Date();
    poids: number = 0;
    sexe: string = "";
    commentaire: string = "";
    categorieAnimalId: number = 0;
    categorieAnimal: CategorieAnimal = new CategorieAnimal();
    causeDecesAnimal: CauseDecesAnimal = new CauseDecesAnimal();
    dateArrivee: Date = new Date();
    typeCremation: TypeCremation = new TypeCremation();
    description: string = ""
    isOkMemorial :boolean |null= false;
    photo1: string = ""
    identification: string = ""
}