<app-header-admin></app-header-admin>
<app-loader *ngIf="loading"></app-loader>
<div id="habilitations">

  <div class="container">
    <div class="row">
      <div class="col-md-2">
        <app-menu-admin></app-menu-admin>
      </div>
      <div class="col-md-10 p-4">

        <h1>Tournées</h1>
        <br>
        <div>
          <button kendoButton (click)="AjouterTournee()" themeColor="primary">Programmer une nouvelle tournée</button>

        </div>
        <br>
        <div class="row">
          <div class="col-12">
            <div class="grid-wrapper">
              <kendo-grid [data]="gridData" (dataStateChange)="dataStateChange($event)" [sortable]="true"
                [sort]="gridState.sort" [columnMenu]="true" [pageSize]="gridState.take" [skip]="gridState.skip"
                [height]="500" [pageable]="true" [filter]="gridState.filter" [filterable]="true"
                (edit)="ModifierTournee($event)" (add)="ExporterTournee($event)">
                <kendo-grid-column field="nom" title="Nom de la tournée"></kendo-grid-column>
                <kendo-grid-column filter="date" field="date" title="Date de la tournée">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.date | date:'dd/MM/yyyy'}}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Action">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <button themeColor="primary" kendoGridEditCommand>Modifier</button>
                    <button id="BtnExport" themeColor="success" kendoButton
                      (click)="ExporterTournee(dataItem)">Exporter</button>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>


<ng-template #dialogActions>
  <button kendoButton (click)="closeTournee()">Fermer</button>
  <button kendoButton themeColor="primary" [disabled]="!this.dialog.content.instance.validate()"
    (click)="EnregistrerTournee()">Enregistrer</button>
</ng-template>
<ng-container #detailTourneeContainer></ng-container>