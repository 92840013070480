<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <form #infoForm="ngForm" name="Form">
                <div id="InfosAnimal">
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Numéro de la housse ">
                                <kendo-textbox [readonly]="true" placeholder="Nom" name="nomSac"
                                    [(ngModel)]="this.commande.detailCremation.sac.id">
                                </kendo-textbox>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Nom du propriétaire">
                                <kendo-textbox [readonly]="true" placeholder="PrenomNom" name="PrenomNom"
                                    [value]="this.commande.client.prenom + ' ' + this.commande.client.nom">
                                </kendo-textbox>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Nom">
                                <kendo-textbox [readonly]="true" placeholder="Nom" name="nom"
                                    [(ngModel)]="this.commande.animal.nom">
                                </kendo-textbox>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Poids (KG)">
                                <kendo-numerictextbox [readonly]="true" [format]="'n0'" placeholder="poids" name="poids"
                                    valu [min]="0" [step]="1" [(ngModel)]="this.commande.animal.poids">
                                </kendo-numerictextbox>
                            </kendo-label>
                        </div>

                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Catégorie ">
                                <kendo-textbox [readonly]="true" placeholder="nomcategorie" name="nomcategorie"
                                    [(ngModel)]="this.commande.animal.categorieAnimal.nom">
                                </kendo-textbox>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Type de crémation">
                                <kendo-textbox [readonly]="true"
                                    [(ngModel)]="this.commande.detailCremation.typeCremationFinal.nom"
                                    name="typeCremationFinal"></kendo-textbox>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Etat actuel">
                                <kendo-textbox [readonly]="true"
                                    [(ngModel)]="this.commande.asso_StatutCommande_Commande[this.commande.asso_StatutCommande_Commande.length-1].statutCommande.nom"
                                    name="statutCommande"></kendo-textbox>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Urne">
                                <kendo-textarea [rows]="8" [readonly]="true" [(ngModel)]="this.commande.commandeUrneProduit"
                                    name="commandeUrneProduit"></kendo-textarea>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div id="ContainerBtn">
                        <button type="button" *ngIf="this.messageSuccessInformation == ''" class="btn btn-primary"
                            (click)="ConfirmerIncineration()">Confirmer
                            la crémation</button>
                    </div>
                    <br>
                    <div *ngIf="this.messageErreurInformation != ''" class="alert alert-danger">
                        {{messageErreurInformation}}
                    </div>
                    <div *ngIf="this.messageSuccessInformation != ''" class="alert alert-success">
                        {{messageSuccessInformation}}
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>