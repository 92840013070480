<app-header-admin></app-header-admin>
<app-loader *ngIf="loading"></app-loader>
<div id="habilitations">

    <div class="container">
        <div class="row">
            <div class="col-md-2">
                <app-menu-admin></app-menu-admin>
            </div>
            <div class="col-md-10 p-4">

                <h1>Conventions de crémations</h1>
                <br>
                <div class="ContainerCommande">
                    <div>
                        <button kendoButton (click)="AjouterCommande()" themeColor="primary">Ajouter une nouvelle
                            convention
                        </button>
                        <br>
                        <br>
                        <button kendoButton (click)="RefreshConvention()" themeColor="warning">Rafraichir les
                            conventions
                        </button>
                    </div>
                    <div>
                        <kendo-label class="k-checkbox-label" [for]="archiver"
                            text="Afficher les conventions archivées"></kendo-label>
                        <input type="checkbox" (click)="AfficherCommandeArchiver()" name="afficherCommandeArchiver"
                            [checked]="this.afficherCommandeArchiver" #archiver kendoCheckBox />
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12">
                        <div class="grid-wrapper">
                            <kendo-grid [rowClass]="rowClass" [data]="gridData"
                                (dataStateChange)="dataStateChange($event)" [sortable]="true" [sort]="gridState.sort"
                                [columnMenu]="true" [pageSize]="gridState.take" [skip]="gridState.skip" [height]="500"
                                [pageable]="true" [filter]="gridState.filter" [filterable]="true">
                                <kendo-grid-column id="txtStatut" field="detailCremation.sacId" title="Numéro">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <p>{{dataItem.detailCremation.sacId}}</p>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column filter="date" id="txtStatut" field="dateSouscription"
                                    title="Date de création">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{dataItem.dateSouscription | date:'dd/MM/yyyy'}}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="client.email"
                                    title="Email du propriétaire"></kendo-grid-column>
                                <kendo-grid-column id="txtStatut" field="PrenomNom"
                                    title="Propriétaire"></kendo-grid-column>
                                <kendo-grid-column id="txtStatut" field="siteCabinet.CabinetVille"
                                    title="Cabinet"></kendo-grid-column>
                                <kendo-grid-column id="txtStatut" field="typeCommande.nom" title="Type de convention">
                                </kendo-grid-column>
                                <kendo-grid-column id="txtStatut" field="txtAsso_StatutCommande_Commande"
                                    title="Statut">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <p
                                            [ngClass]="{'text-green': dataItem.txtAsso_StatutCommande_Commande === 'Incinéré'}">
                                            {{dataItem.txtAsso_StatutCommande_Commande}}
                                        </p>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="lblCommandeProduitsText" title="Détail convention"
                                    [filterable]="true">
                                    
                                </kendo-grid-column>
                                <kendo-grid-column title="Action">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <img src="../../../assets/TechnicienChauffeur/editer.png" alt=""
                                            (click)="modifieCompte(dataItem)" style="cursor: pointer;">
                                        <img *ngIf="dataItem.typeCommandeId == 2"
                                            src="../../../assets/TechnicienChauffeur/fichier-pdf.png" alt=""
                                            (click)="ImprimerFicheConvention(dataItem)" style="cursor: pointer;">
                                        <img *ngIf="dataItem.typeCommandeId == 2 && this._GlobalService.isAdmin()"
                                            src="../../../assets/Admin/Trash.svg" alt=""
                                            (click)="SupprimerCommande(dataItem.id)" style="cursor: pointer;">
                                    </ng-template>
                                </kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </div>
</div>

<!-- Fiche commande animal -->
<ng-template #dialogActions>
    <button kendoButton (click)="closeClient()">Fermer</button>
    <button kendoButton themeColor="primary" (click)="enregistreClient()"
        *ngIf="this.dialog.content.instance.IndexTabStrip ==2"
        [disabled]="!this.dialog.content.instance.validate()">Enregistrer</button>
    <button kendoButton themeColor="primary" (click)="SuivantCommande()"
        *ngIf="this.dialog.content.instance.IndexTabStrip ==0"
        [disabled]="!this.dialog.content.instance.IsSuivantValide()">Suivant</button>
    <button kendoButton themeColor="primary" (click)="SuivantCommande()"
        *ngIf="this.dialog.content.instance.IndexTabStrip ==1"
        [disabled]="!this.dialog.content.instance.IsSuivantValideCremation()">Suivant</button>
</ng-template>

<!-- Fiche commande article -->
<ng-template #dialogActionsCommandeArticle>
    <button kendoButton (click)="closeClient()">Fermer</button>
    <button kendoButton themeColor="primary" (click)="enregistreClient()">Enregistrer</button>

</ng-template>
<ng-container #detailCompteContainer></ng-container>