<app-header-technicien-chauffeur [Titre]="'Créer ma fournée'"></app-header-technicien-chauffeur>
<app-loader *ngIf="loading"></app-loader>
<div class="Container">
    <div class="ContainerFournee">

        <div id="ListeTypeCremation">
            <label id="lblTypeCremation">Sélectionner le type de crémation</label>
            <kendo-dropdownlist (selectionChange)="GetCommandeByTypeCremation($event)" [(ngModel)]="TypeCremationSelect"
                [data]="TypesCremation" [textField]="'nom'" [valueField]="'id'"></kendo-dropdownlist>
        </div>
        <br>
        <div id="AjoutEtiquetteManuellement">
            <div id="ContainerAjoutEtiquetteManuelle">
                <h3>N°</h3>
                <kendo-numerictextbox #ajoutEtiquetteTextBox [min]="1" [step]="1" [decimals]="0" format="n0"
                    id="AjoutEtiquetteManuelle" name="AjoutEtiquetteManuelle"
                    [(ngModel)]="this.TxtAjoutEtiquetteManuelle">
                </kendo-numerictextbox>
            </div>
            <kendo-button id="btnAjoutEtiquetteManuellent"
                (click)="AjouterEtiquetteManuellement()">Ajouter</kendo-button>
        </div>
        <br>
        <div class="ContainerScan">
            <!-- ngx-scanner-qrcode -->
            <ngx-scanner-qrcode class="Scanner" #action="scanner" [config]="this._GlobalService.config"
                (event)="onEvent($event, action)"></ngx-scanner-qrcode>
            <!-- loading -->
            <p *ngIf="action.isLoading">⌛ Chargement...</p>
        </div>
        <br>
        <div *ngIf="this.messageSuccesInformation != ''" class="alert alert-success">
            {{messageSuccesInformation}}
        </div>

        <div id="ListeAnimauxCrémation">
            <label id="lblTypeCremation">Liste des animaux dans la fournée</label>
            <app-carte-animal [Commandes]="GetCommandeInFournee()"></app-carte-animal>
        </div>
        <br>
        <button kendoButton id="btnValider" *ngIf="GetCommandeInFournee().length > 0"
            (click)="this.CreationFournee()">Valider</button>
        <br>
        <div id="ListeAnimauxCrémation">
            <label id="lblTypeCremation">Liste des animaux disponibles</label>
            <app-carte-animal [Commandes]="GetCommandeNotInFournee()"></app-carte-animal>
        </div>
    </div>

</div>
<audio #audioPlayer style="display: none;">
    <source src="../../../../assets/Sound/ScanQrCode.mp3" type="audio/mpeg">
</audio>
<audio #audioPlayerWrong style="display: none;">
    <source src="../../../../assets/Sound/ErrorScanQrCode.mp3" type="audio/mpeg">
</audio>

<kendo-dialog (close)="messageErreurInformation = ''" title="Erreur" *ngIf="messageErreurInformation !=''">
    <p style="text-align: center; color: black;">
        {{messageErreurInformation}}
    </p>
</kendo-dialog>