import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { State, process } from '@progress/kendo-data-query';
import { DemandeSac } from '../../_models/DemandeSac';
import { GlobalService } from '../../_services/GlobalService';
import { SacService } from '../../_services/SacService';
import { EtiquetteViewComponent } from '../_Views/etiquette-view/etiquette-view.component';

@Component({
  selector: 'app-etiquette-admin',
  templateUrl: './etiquette-admin.component.html',
  styleUrls: ['./etiquette-admin.component.scss']
})
export class EtiquetteAdminComponent {
  @ViewChild('dialogActions') dialogActions!: TemplateRef<unknown>;
  public dialog !: DialogRef;

  @ViewChild("detailSacContainer", { read: ViewContainerRef })
  public detailSacContainer!: ViewContainerRef;
  @ViewChild('tabstrip') ts!: TabStripComponent;
  public gridData!: GridDataResult;
  public gridState: State = {
    skip: 0,
    take: 25,
  };
  loading: boolean = false;
  public Sacs: DemandeSac[] = new Array()


  public constructor(private _SacService: SacService,
    public _GlobalService: GlobalService,
    private _dialogService: DialogService) { }

  async ngOnInit(): Promise<void> {
    //charge le tableau des utilisateurs
    this.ChargerListeSac();
  }

  public async ChargerListeSac() {
    this.loading = true;

    let reponse = await this._SacService.GetAllSacs();

    this.Sacs = reponse.objet;
    this.Sacs.forEach(element => {
      element.siteCabinetVeterinaire.CabinetVille = element.siteCabinetVeterinaire.cabinetVeterinaire.nom + " " + element.siteCabinetVeterinaire.ville
      element.dateApprovisionnement = new Date(element.dateApprovisionnement)
    });

    this.dataStateChange(this.gridState);
    this.loading = false;
  }

  public async dataStateChange(state: State) {
    this.gridState = state;
    await this.ChargerTableau();
  }
  ///charge les utilisateurs dans le tableau
  public async ChargerTableau() {
    // Init des données pour la grille
    this.gridData = process(this.Sacs, this.gridState);
  }

  public ouvreDetailSac(sac: DemandeSac, nouveau: boolean) {
    this.dialog = this._dialogService.open({
      title: "Fiche d'approvisionnement de housse",
      width: '90%',
      height: '90%',
      //actions: [cancelAction, saveAction],
      actions: this.dialogActions,

      appendTo: this.detailSacContainer,
      closeTitle: "Fermer",
      content: EtiquetteViewComponent,
    });

    let DetailSac = this.dialog.content.instance;
    DetailSac.DemandeSac = sac;
    DetailSac.nouveau = nouveau;
  }

  //ajouter un nouveau Sac
  public AjouterSac() {
    this.ouvreDetailSac(new DemandeSac(), true);
  }

  //ferme la boite de dialogue
  public closeSac(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  ModifierSac({ dataItem }: any) {
    this.ouvreDetailSac(dataItem as DemandeSac, false);
  }

  public async EnregistrerSac() {

    let DetailClient = (this.dialog.content.instance as EtiquetteViewComponent);

    if (DetailClient.validate() && await DetailClient.EnregistrerSac()) {

      this.loading = true;

      await this.ChargerListeSac();

      this.closeSac();

      this.loading = false;
    }

  }
}
