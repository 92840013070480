import { Animal } from "./Animal";
import { Asso_Commande_Produit } from "./Asso_Commande_Produit ";
import { Asso_StatutCommande_Commande } from "./Asso_StatutCommande_Commande";
import { Client } from "./Client";
import { DetailCremation } from "./DetailCremation";
import { SiteCabinetVeterinaire } from "./SiteCabinetVeterinaire";
import { StatutCommandeErreur } from "./StatutCommandeErreur";
import { TypeCommande } from "./TypeCommande";

export class Commande {
    id: number = 0;
    isAnimalApporteDirectement: boolean = false;
    isAnimalsuiviCabinet: boolean = true;
    animalId: number = 0;
    animal: Animal = new Animal();
    clientId: number = 0;
    client: Client = new Client();
    detailCremationId: number = 0;
    detailCremation: DetailCremation = new DetailCremation();
    siteCabinetId: number = 0;
    siteCabinet: SiteCabinetVeterinaire = new SiteCabinetVeterinaire();
    dateSouscription: Date = new Date();
    asso_StatutCommande_Commande: Asso_StatutCommande_Commande[] = new Array();
    txtAsso_StatutCommande_Commande: string = ""
    isCremationTermine: boolean = false
    dateArrive: Date = new Date()
    typeCommandeId: number = 0
    typeCommande: TypeCommande = new TypeCommande()
    archiver: boolean = false
    isInFournee: boolean = false
    asso_Commande_Produits: Asso_Commande_Produit[] = new Array<Asso_Commande_Produit>();
    lblCommandeProduits: string = ""
    lblDeces: string = ""
    nbJourDeces: number = 0
    dateDebut: Date = new Date()
    dateFin: Date = new Date()
    isPayer: boolean = false
    commandeChangementCremation: Commande | null = null
    statutCommandeErreur: StatutCommandeErreur | null = null
    commandeUrneProduit: string = ""
    lblCommandeProduitsText: string = ""
    idPostWordpress = 0
    isOkMemorial: boolean = false;
}