import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "./GlobalService";
import { ReponseAPI } from "../_models/ReponseAPI";
import { Commande } from "../_models/Commande";
import { EnumStatutCommande } from "../_models/Filter/FilterCommande";
import { DataModelUpdateCommandeArticle } from "../_models/DataModelController/DataModelUpdateCommandeArticle";
import { DataModelPaiement } from "../_models/DataModelController/DataModelPaiement";

@Injectable({
    providedIn: 'root'
})

export class CommandeService {

    CommandeScannees = new Array<Commande>()

    public constructor(private http: HttpClient, private router: Router, private _GlobalService: GlobalService) { }

    public async GetAllCommandes(Filter: EnumStatutCommande): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Commande/Commandes/" + Filter)
    }
    public async GetAllCommandeRaccourci(Filter: EnumStatutCommande): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Commande/CommandeRaccourci/" + Filter)
    }
    public async GetCommandeByIds(ids: Array<number>): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Commande/Commandes/Ids", ids)
    }
    public async GetCommandeBySacId(SacId: number): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Commande/Sac/" + SacId)
    }
    public async AddCommande(data: Commande): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Commande", data)
    }
    public async UpdateCommande(data: Commande): Promise<ReponseAPI> {
        return await this._GlobalService.SendPUTRequest("Commande", data)
    }
    public async UpdateCommandeArticle(data: DataModelUpdateCommandeArticle): Promise<ReponseAPI> {
        return await this._GlobalService.SendPUTRequest("Commande/CommandeArticle", data)
    }
    public async UpdateFicheAnimal(data: Commande): Promise<ReponseAPI> {
        return await this._GlobalService.SendPUTRequest("Commande/FicheAnimal", data)
    }
    public async GetCommandeByStatutId(statutCommandeId: number): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Commande/StatutCommande/" + statutCommandeId)
    }
    public async GetCommandeByTypeCremation(typeCremationId: number): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Commande/TypeCremation/" + typeCremationId)
    }
    public async CreationFournee(CommandeIds: number[]): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Commande/CreationFournee/", CommandeIds)
    }
    public async ImprimerFicheConvention(CommandeId: number): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Commande/FicheConvention/" + CommandeId)
    }
    public async PasserPaiement(data: DataModelPaiement): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("Commande/Paiement/", data)
    }
    public async SetCommandePayer(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("Commande/Payer")
    }
    public async SupprimerCommande(commandeId : number): Promise<ReponseAPI> {
        return await this._GlobalService.SendDeleteRequest("Commande/" + commandeId)
    }

}