<app-header-technicien-chauffeur [Titre]="'Choix de la tournée'"></app-header-technicien-chauffeur>
<app-loader *ngIf="loading"></app-loader>
<div class="Container">
    <div class="ContainerFournee">
        <div id="ListeTypeCremation">
            <label id="lblTypeCremation">Sélectionner la tournée</label>
            <kendo-dropdownlist (selectionChange)="UpdateTournee($event)" [data]="Tournees" [(ngModel)]="Tournee" valueField="id"
                textField="nom"></kendo-dropdownlist>
        </div>
    </div>
    <button (click)="this.DechargementTournee()" id="CreationFournee">
        <span id="txtCreation">Décharger la tournée</span>
    </button>
    <br>
    <div *ngIf="this.messageSuccesInformation != ''" class="alert alert-success">
        {{messageSuccesInformation}}
    </div>
    <br>
    <p id="TextChoisirCabinet">Choisir un cabinet vétérinaire</p>
    <br>
    <app-carte-tournee [Tournees]="Tournee.tourneeEtablissements"></app-carte-tournee>
</div>