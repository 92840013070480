<app-header-admin></app-header-admin>
<app-loader *ngIf="loading"></app-loader>
<div id="habilitations">

    <div class="container">
        <div class="row">
            <div class="col-md-2">
                <app-menu-admin></app-menu-admin>
            </div>
            <div class="col-md-10 p-4">
                <h1>Inventaire des housses affectées</h1>
                <br>
                <div *ngIf="this.messageErreurInformation != ''" class="alert alert-danger">
                    {{messageErreurInformation}}
                </div>
                <br>
                <div class="row">
                    <div class="col-md-2">
                        <kendo-floatinglabel text="Date de début *">
                            <kendo-datepicker name="dateDebut" [(ngModel)]="this.data.dateDebut"></kendo-datepicker>
                        </kendo-floatinglabel>
                    </div>
                    <div class="col-md-2">
                        <kendo-floatinglabel text="Date de fin *">
                            <kendo-datepicker name="dateFin" [(ngModel)]="this.data.dateFin"></kendo-datepicker>
                        </kendo-floatinglabel>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-4" style="text-align: center;">
                        <button style="width: 10vw;" kendoButton (click)="getSac()"
                            themeColor="primary">Actualiser</button>
                    </div>
                </div>
                <br><br>

                <div class="row">
                    <div class="col-12">
                        <div class="grid-wrapper">
                            <kendo-grid [data]="gridData" [sortable]="true" [sort]="gridState.sort" [columnMenu]="true"
                                [pageSize]="gridState.take" [skip]="gridState.skip" [height]="500" [pageable]="true"
                                [filter]="gridState.filter" [filterable]="true"
                                (dataStateChange)="dataStateChange($event)">
                                <ng-template kendoGridToolbarTemplate>
                                    <button type="button" themeColor="primary" kendoGridExcelCommand>
                                        Exporter
                                    </button>
                                </ng-template>
                                <kendo-grid-column field="site.CabinetVille" title="Cabinet">
                                </kendo-grid-column>
                                <kendo-grid-column field="nbTailleXS" title="Taille XS">
                                </kendo-grid-column>
                                <kendo-grid-column field="nbTailleS" title="Taille S">
                                </kendo-grid-column>
                                <kendo-grid-column field="nbTailleM" title="Taille M">
                                </kendo-grid-column>
                                <kendo-grid-column field="nbTailleL" title="Taille L">
                                </kendo-grid-column>
                                <kendo-grid-column field="nbTailleXL" title="Taille XL">
                                </kendo-grid-column>
                                <kendo-grid-excel fileName="Sacs.xlsx"></kendo-grid-excel>
                            </kendo-grid>
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </div>
</div>