import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../_services/GlobalService';
import { UtilisateurService } from '../../_services/UtilisateurService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-choix-roles',
  templateUrl: './choix-roles.component.html',
  styleUrls: ['./choix-roles.component.scss']
})
export class ChoixRolesComponent implements OnInit {

  public constructor(public _GlobalService: GlobalService, private _UtilisateurService: UtilisateurService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    this._GlobalService.utilisateur = (await this._UtilisateurService.GetInfosUser()).objet;
  }

  ChoixRole(NomRole: string) {
    localStorage.setItem("Role", NomRole)
    switch (NomRole) {
      case "Technicien":
        this.router.navigateByUrl("/Technicien/Accueil")
        break;
      case "Chauffeur":
        this.router.navigateByUrl("/Chauffeur/accueil")
        break;
    }

  }
}
