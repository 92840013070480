import { Component, OnInit } from '@angular/core';
import { TourneeService } from '../../../_services/TourneeService';
import { Tournee } from '../../../_models/Tournee';

@Component({
  selector: 'app-choix-tournee',
  templateUrl: './choix-tournee.component.html',
  styleUrls: ['./choix-tournee.component.scss']
})
export class ChoixTourneeComponent implements OnInit {

  Tournees: Tournee[] = [];
  Tournee: Tournee = new Tournee();
  loading: boolean = false;

  messageSuccesInformation = ""

  constructor(private _TourneeService: TourneeService) { }

  async ngOnInit() {
    this.loading = true

    this.Tournees = (await this._TourneeService.GetTourneesToday()).objet;

    var tourneelocal = localStorage.getItem("tournee")

    if (tourneelocal != null) {
      var tournee = JSON.parse(tourneelocal)

      if (this.Tournees.filter(d => d.id == tournee.id).length > 0) {
        this.Tournee = tournee
      } else {
        this.Tournee = this.Tournees[0];
      }

    } else {
      this.Tournee = this.Tournees[0];
    }

    this.loading = false
  }

  async DechargementTournee() {
    let reponse = await this._TourneeService.DechargerTournee(this.Tournee.id)

    this.messageSuccesInformation = reponse.message
  }

  UpdateTournee(Tournee: Tournee) {
    this.Tournee = Tournee

    localStorage.setItem("tournee", JSON.stringify(this.Tournee))

  }

}
