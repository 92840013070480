import { Component, Input, OnInit } from '@angular/core';
import { Commande } from '../../../_models/Commande';
import { Router } from '@angular/router';
import { GlobalService } from '../../../_services/GlobalService';

@Component({
  selector: 'app-carte-animal',
  templateUrl: './carte-animal.component.html',
  styleUrls: ['./carte-animal.component.scss']
})
export class CarteAnimalComponent implements OnInit {
  @Input() Commandes: Commande[] = new Array();

  dateAlert: Date = new Date();

  constructor(public router: Router, private _GlobalService: GlobalService) {
    this.dateAlert.setDate(this.dateAlert.getDate() - 5)
  }

  ngOnInit(): void {    
  }
}
