import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "./GlobalService";
import { ReponseAPI } from "../_models/ReponseAPI";
import { DataModelUpdateStatutCommande } from "../_models/DataModelController/DataModelUpdateStatutCommande";
import { Asso_StatutCommande_Commande } from "../_models/Asso_StatutCommande_Commande";
import { DataModelUpdateStatutAdmin } from "../_models/DataModelController/DataModelUpdateStatutAdmin";

@Injectable({
    providedIn: 'root'
})
export class StatutCommandeService {
    public constructor(private http: HttpClient, private router: Router, private _GlobalService: GlobalService) { }

    public async GetAllStatuts(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("StatutCommande/Statuts");
    }
    public async GetById(Id: number): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("StatutCommande/Statuts/" + Id);
    }
    public async UpdateStatutCommande(data: DataModelUpdateStatutCommande[]): Promise<ReponseAPI> {
        return await this._GlobalService.SendPUTRequest("StatutCommande/Commande", data);
    }
    public async UpdateRetirerStatutCommande(data: DataModelUpdateStatutCommande): Promise<ReponseAPI> {
        return await this._GlobalService.SendPUTRequest("StatutCommande/RetirerStatutCommande", data);
    }
    public async RetirerStatutCommandeAdmin(data: Asso_StatutCommande_Commande): Promise<ReponseAPI> {
        return await this._GlobalService.SendPUTRequest("StatutCommande/RetirerStatutCommandeAdmin", data);
    }
    public async GetAllStatutsCommandeErreur(): Promise<ReponseAPI> {
        return await this._GlobalService.SendGETRequest("StatutCommande/Erreurs");
    }
    public async UpdateStatutCommandeAdmin(data:DataModelUpdateStatutAdmin): Promise<ReponseAPI> {
        return await this._GlobalService.SendPOSTRequest("StatutCommande/Commande/Statut",data);
    }

}
