import { Component, Input, OnInit } from '@angular/core';
import { Commande } from '../../../_models/Commande';
import { DataModelUpdateStatutCommande } from '../../../_models/DataModelController/DataModelUpdateStatutCommande';
import { StatutCommandeService } from '../../../_services/StatutCommandeService';

@Component({
  selector: 'app-fiche-animal-incineration-view',
  templateUrl: './fiche-animal-incineration-view.component.html',
  styleUrls: ['./fiche-animal-incineration-view.component.scss']
})
export class FicheAnimalIncinerationViewComponent implements OnInit {
  @Input() commande: Commande = new Commande()
  messageErreurInformation: string = ""
  messageSuccessInformation: string = ""

  constructor(private _StatutCommandeService: StatutCommandeService) {
  }

  ngOnInit(): void {
    if (this.commande.commandeUrneProduit == "") {
      this.commande.commandeUrneProduit = "Tube de dispersion"
    }
  }

  public async ConfirmerIncineration() {
    this.messageErreurInformation = ""
    this.messageSuccessInformation = ""

    //Créer l'objet de la requête
    let data: DataModelUpdateStatutCommande = new DataModelUpdateStatutCommande()
    data.etiquette = this.commande.detailCremation.sacId
    data.statuCommandeId = 5

    let resultEtiquetteTypeSac: DataModelUpdateStatutCommande[] = [];
    resultEtiquetteTypeSac.push(data)

    let reponse = await this._StatutCommandeService.UpdateStatutCommande(resultEtiquetteTypeSac)

    if (reponse.statusCode == 500) {
      this.messageErreurInformation = reponse.messageException
    } else {
      this.messageSuccessInformation = reponse.message
    }
  }
}
