<app-header-technicien-chauffeur [Titre]="'Liste des animaux à crématiser'"></app-header-technicien-chauffeur>
<div class="Container">
    <div id="ContainerScan">
        <button kendoButton id="btnScan" (click)="this.OuvrirQrCodeMulti()">Validation crémation individuelle<img src="../../../../assets/TechnicienChauffeur/ScanMultiple.svg" alt=""></button>
    </div>
    <div class="ContainerFournee">
        <button (click)="this.GoToFourneeAccueil()" id="CreationFournee">
            <span id="txtCreation">Liste des crémations en cours</span>
            <span id="arrow">➜</span>
        </button>
        <br>
        <p id="Titre">Animaux dans la chambre froide</p>
        <br>
        <app-carte-animal [Commandes]="Commandes"></app-carte-animal>
    </div>
</div>