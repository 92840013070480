<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <form #infoForm="ngForm" name="Form">
                <div id="InfosAnimal">
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Numéro de la housse *">
                                <kendo-dropdownlist name="id" textField="id" valueField="id" [data]="Sacs"
                                    [(ngModel)]="this.commande.detailCremation.sac" required [filterable]="true">
                                </kendo-dropdownlist>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Nom du propriétaire">
                                <kendo-textbox [readonly]="true" placeholder="PrenomNom" name="PrenomNom"
                                    [value]="this.commande.client.prenom + ' ' + this.commande.client.nom">
                                </kendo-textbox>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Nom">
                                <kendo-textbox placeholder="Nom" name="nom" [(ngModel)]="this.commande.animal.nom">
                                </kendo-textbox>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Poids (KG)">
                                <kendo-numerictextbox [format]="'n0'" placeholder="poids" name="poids" valu [min]="0"
                                    [step]="1" [(ngModel)]="this.commande.animal.poids">
                                </kendo-numerictextbox>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Catégorie *">
                                <kendo-dropdownlist required valueField="nom" textField="nom" name="categorieAnimal"
                                    [data]="CategorieAnimals" [(ngModel)]="this.commande.animal.categorieAnimal"
                                    required [filterable]="true" (filterChange)="handleFilterCategorieAnimal($event)">

                                </kendo-dropdownlist>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Type de crémation">
                                <kendo-textbox [readonly]="true"
                                    [(ngModel)]="this.commande.detailCremation.typeCremationFinal.nom"
                                    name="typeCremationFinal"></kendo-textbox>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Etat actuel">
                                <kendo-textbox [readonly]="true"
                                    [(ngModel)]="this.commande.asso_StatutCommande_Commande[this.commande.asso_StatutCommande_Commande.length-1].statutCommande.nom"
                                    name="statutCommande"></kendo-textbox>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12">
                            <kendo-label class="k-form" text="Erreur ">
                                <kendo-dropdownlist required valueField="nom" textField="nom"
                                    name="statutCommandeErreur" [data]="AllStatutCommandeErreur"
                                    [(ngModel)]="this.commande.statutCommandeErreur" required>
                                </kendo-dropdownlist>
                            </kendo-label>
                        </div>
                    </div>
                    <br>
                    <div *ngIf="this.messageErreurInformation != ''" class="alert alert-danger">
                        {{messageErreurInformation}}
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>