import { EventEmitter, Injectable, Output } from '@angular/core'
import { Utilisateur } from '../_models/Utilisateur';
import { Client } from '../_models/Client';
import { ReponseAPI } from '../_models/ReponseAPI';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TourneeEtablissement } from '../_models/TourneeEtablissement';
import { Commande } from '../_models/Commande';
import { TokenService } from './token.service';
import { ScannerQRCodeConfig } from 'ngx-scanner-qrcode';
import { Produit } from '../_models/Produit';
@Injectable()
export class GlobalService {

  commandes: Commande[] = new Array<Commande>()

  tournee: TourneeEtablissement = new TourneeEtablissement()
  //permet de savoir dans quel onglet on se situe dans la fiche client pour changer le bouton enregistrer/fermer
  @Output() selectedTabClient: EventEmitter<number> = new EventEmitter();
  @Output() IndexTabCommande: number = 0;
  @Output() public HeadersHTTP: HttpHeaders = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('accept', '*/*')
    .set('Access-Control-Allow-Origin', '*')

  public config: ScannerQRCodeConfig = {
    isBeep: false,
    constraints: {

    },
    canvasStyles: [
      { /* layer */
        lineWidth: 0,
        fillStyle: 'rgba(255, 255, 255, .4)',
        strokeStyle: 'rgba(255, 255, 255, .4)',

      },
      { /* text */
        font: '0 serif',
        fillStyle: '#ff0000',
        strokeStyle: '#ff0000',
      }
    ],
  };

  changeOngletClient(tab: number) {
    this.selectedTabClient.emit(tab);
    this.IndexTabCommande = tab;
  }

  public utilisateur: Utilisateur | undefined = new Utilisateur();
  public client: Client | undefined = new Client();
  public roleUtilisateur: string = ""
  isOpenMenuMonCompte: boolean = true;
  isOpenSousMenu: boolean = false;

  private reponseAPI: ReponseAPI = new ReponseAPI()

  constructor(private http: HttpClient,
    private _TokenService: TokenService
  ) { }

  public isAdmin(): boolean {
    return this._TokenService.getRoleInToken() == "administrateur"
  }
  public isVeterinaire(): boolean {
    return this._TokenService.getRoleInToken() == "vétérinaire"
  }

  chargeUtilisateur(utilisateur: Utilisateur) {
    this.utilisateur = utilisateur;
  }

  async SendPOSTRequest(route: string, data: any): Promise<ReponseAPI> {
    await lastValueFrom(this.http.post<ReponseAPI>(environment.apiUrl + route, JSON.stringify(data)))
      .then(data => {
        this.reponseAPI = data
      },
        error => {
          this.reponseAPI = new ReponseAPI()
          this.reponseAPI.statusCode = 500
          this.reponseAPI.messageException = error.error.messageException
        });

    return this.reponseAPI;
  }
  async SendPUTRequest(route: string, data: any): Promise<ReponseAPI> {
    await lastValueFrom(this.http.put<ReponseAPI>(environment.apiUrl + route, JSON.stringify(data)))
      .then(data => {
        this.reponseAPI = data
      },
        error => {
          this.reponseAPI = new ReponseAPI()
          this.reponseAPI.statusCode = 500
          this.reponseAPI.messageException = error.error.messageException
        });

    return this.reponseAPI;
  }
  async SendGETRequest(route: string): Promise<ReponseAPI> {
    await lastValueFrom(this.http.get<ReponseAPI>(environment.apiUrl + route))
      .then(data => {
        this.reponseAPI = data
      },
        error => {
          this.reponseAPI = new ReponseAPI()
          this.reponseAPI.statusCode = 500
          this.reponseAPI.messageException = error.error.messageException
        });

    return this.reponseAPI;
  }
  async SendDeleteRequest(route: string): Promise<ReponseAPI> {
    await lastValueFrom(this.http.delete<ReponseAPI>(environment.apiUrl + route))
      .then(data => {
        this.reponseAPI = data
      },
        error => {
          this.reponseAPI = new ReponseAPI()
          this.reponseAPI.statusCode = 500
          this.reponseAPI.messageException = error.error.messageException
        });

    return this.reponseAPI;
  }


}